import React from "react";
import { useTranslation } from "react-i18next";

const LoaderTestimonials = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="animate-pulse flex flex-col items-center">
                <div className="flex">
                    <div className="rounded-md bg-slate-700 opacity-15 mt-20 sm:mt-24 xl:mt-[200px] mb-6">
                        <h2 className='invisible font-semibold font-titillium text-3xl sm:text-4xl xl:text-5xl'>
                            {t("index.testimonials.heading")}
                        </h2>
                    </div>
                </div>
                <div className="flex">
                    <div className="rounded-md bg-slate-700 opacity-15">
                        <p className='invisible text-base sm:text-xl font-normal font-sans'>
                            {t("index.testimonials.subheading")}
                        </p>
                    </div>
                </div>
            </div>
            <div className="animate-pulse mt-9 xl:mt-14">
                <div className='mx-auto pb-2 flex space-x-5 xl:space-x-10'>
                    <div className='rounded-md bg-slate-700 opacity-15 w-full max-w-[320px] sm:max-w-[360px] h-[210px]'>
                    </div>
                    <div className='hidden sm:block rounded-md bg-slate-700 opacity-15 w-full max-w-[320px] sm:max-w-[360px] h-[210px]'>
                    </div>
                    <div className='hidden xl:block rounded-md bg-slate-700 opacity-15 w-full max-w-[320px] sm:max-w-[360px] h-[210px]'>
                    </div>
                </div>
                <div className="flex justify-center w-full">
                    <div className="rounded-md bg-slate-700 opacity-15 h-[30px] w-[120px] mt-[30px]"></div>
                </div>
            </div>
        </>
    )
}

export default LoaderTestimonials;