import React, { createContext, useContext, useEffect, useState } from "react";
import i18n from "i18next";
const LanguageContext = createContext();
export const useLanguage = () => {
  return useContext(LanguageContext);
};
export const LanguageProvider = ({ children }) => {
  const isLocalStorageEnabled = () => {
    try {
      const key = `__storage__test`;
      window.localStorage.setItem(key, null);
      window.localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getLanguageFromUrl = () => {
    const url = window.location.href;
    let languageCode;

    if (isLocalStorageEnabled()) {
      try {
        if (window.localStorage.getItem("lang")) {
          languageCode =
            window.localStorage.getItem("lang") === "pt"
              ? "br"
              : url.split("/")[3].slice(0, 2) === "ar"
              ? "ar"
              : "es";
        } else {
          const geo = url.split("/")[3].slice(0, 2);
          languageCode = geo === "pt" ? "br" : geo;
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      const geo = url.split("/")[3].slice(0, 2);
      languageCode = geo === "pt" ? "br" : geo;
    }

    localStorage.setItem("lang", languageCode === "br" ? "pt" : "es");

    switch (languageCode) {
      case "br":
        return "pt-BR";
      case "ar":
        return "es-AR";
      default:
        return "es";
    }
  };
  const [language, setLanguage] = useState(getLanguageFromUrl);
  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  const contextValue = {
    language,
    setLanguage: changeLanguage,
  };
  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};
