import React from "react";
import { useTranslation } from "react-i18next";

const LoaderFeatures = ({ breakPointControl }) => {

    const { t } = useTranslation();
    return (
        <>
            <div className='w-full relative lg:mb-[90px] animate-pulse'>
                <div className='flex flex-col place-items-center justify-around relative h-auto pb-16 px-6 sm:grid sm:grid-cols-[auto_358px] sm:pb-14 sm:mt-[170px] sm:max-w-[633px] mx-auto xl:flex xl:flex-row xl:items-center xl:justify-start xl:max-w-[1210px] xl:px-0 xl:pb-0' >
                    <div className='flex justify-center items-center mt-20 sm:mt-0 mb-9 sm:mb-0 xl:w-[458px] xl:h-[455px]'>
                        <div className="rounded-md bg-slate-700 opacity-15 w-[164px] h-[190px] xl:w-[280px] xl:h-[308px]"></div>
                    </div>
                    <div className='w-full xl:w-[606px]'>
                        <h2 className='rounded-md bg-slate-700 opacity-15 font-titillium text-4xl xl:text-[64px] sm:mt-10 font-bold sm:font-semibold text-center mb-3 sm:max-w-[200px] sm:mx-auto xl:max-w-full xl:pb-4 xl:mt-0 xl:font-bold'>
                            <span className="invisible">{t("index.features.1.title")}</span>
                        </h2>
                        <p className='rounded-md bg-slate-700 opacity-15 font-titillium text-xl font-semibold text-center text-[#323334] leading-7'>
                            <span className="invisible">{t("index.features.1.content")}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className='w-full relative bg-blue-secondary'>
                <div className='animate-pulse flex flex-col place-items-center justify-around relative h-auto pb-5 text-center sm:grid sm:grid-cols-[auto_358px] sm:max-w-[633px] mx-auto px-4 sm:px-0 xl:flex xl:flex-row-reverse xl:items-center xl:justify-center xl:max-w-[1210px] xl:px-0 xl:pb-0'>
                    <div className='w-[231px] h-[230px] mt-8 mb-4 sm:m-0 mx-auto xl:w-[400px] xl:h-[400px] xl:transform xl:-scale-x-100 scale-100 flex items-center justify-center'>
                        <div className="rounded-md bg-slate-700 opacity-15 m-10 w-[83px] h-[168px] xl:w-[124px] xl:h-[297px]"></div>
                    </div>
                    <div className='sm:mt-24 sm:mb-20 xl:max-w-[606px] xl:mt-36 xl:mb-48'>
                        <h2 className=' rounded-md bg-slate-700 opacity-15 font-titillium text-4xl font-semibold sm:max-w-[200px] mx-auto xl:text-[64px] xl:leading-[70px] xl:max-w-[480px] xl:font-bold'>
                            <span className="invisible"> {t("index.features.2.title")} </span>
                        </h2>
                        <p className='rounded-md bg-slate-700 opacity-15 font-titillium text-xl font-normal text-center leading-7 mb-6 xl:max-w-[520px] xl:mb-9 xl:font-medium xl:leading-6 mt-3'>
                            <span clasName="invisible"> {t("index.features.2.content")} </span>
                        </p>
                        {!breakPointControl?.isXS && <div className="mx-auto w-[333px] h-[56px] rounded-md bg-slate-700 opacity-15"></div>}
                    </div>
                </div>
            </div>
            <div className='w-full relative animate-pulse'>
                <div className='flex flex-col place-items-center justify-around relative h-auto pb-16 sm:pb-8 sm:grid sm:grid-cols-[auto_358px] sm:max-w-[633px] sm:mx-auto xl:flex xl:flex-row xl:items-center xl:justify-center xl:max-w-[1210px] xl:px-0 xl:pb-0 ' >
                    <div className='w-[227px] h-[229px] mt-10 sm:mt-0 xl:w-[458px] xl:h-[455px] flex items-center justify-center '>

                        <div className="rounded-md bg-slate-700 opacity-15 w-[156px] h-[156px] xl:w-[321px] xl:h-[324px]"></div>
                    </div>
                    <div className='px-6 sm:px-0 mt-14 xl:px-0 xl:flex xl:items-center xl:justify-center xl:flex-col'>
                        <h2 className='rounded-md bg-slate-700 opacity-15 font-titillium text-3xl sm:text-4xl font-semibold text-center text-blue-2 sm:max-w-[260px] sm:mt-8 mx-auto xl:text-[64px] xl:font-bold xl:max-w-[606px] xl:leading-[70px] xl:mt-0'>
                            <span className="invisible"> {t("index.features.3.title")} </span>
                        </h2>
                        <p className='rounded-md bg-slate-700 opacity-15 mt-3 font-titillium text-xl font-semibold text-center text-neutral-black leading-7 xl:max-w-[520px]'>
                            <span className="invisible"> {t("index.features.3.content")} </span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoaderFeatures;