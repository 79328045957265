import React from "react";
import { useTranslation } from "react-i18next";

const LoaderFaq = () => {

    const { i18n } = useTranslation();

    return (
        <div
            className={
                "animate-pulse w-full mx-auto max-w-[358px] sm:max-w-[633px] sm:mt-20 px-4 xl:mt-32 xl:max-w-[1210px]" +
                (i18n.language !== "ar" ? " pb-16" : "")
            }
        >
            <div className='bg-slate-700 opacity-15 h-[203px] rounded-md w-full'></div>
            <div className="space-y-4 mt-4">
                <div className='bg-slate-700 opacity-15 h-[93px] rounded-md w-full'></div>
                <div className='bg-slate-700 opacity-15 h-[93px] rounded-md w-full'></div>
                <div className='bg-slate-700 opacity-15 h-[93px] rounded-md w-full'></div>
                <div className='bg-slate-700 opacity-15 h-[93px] rounded-md w-full'></div>
            </div>
        </div>
    )
}

export default LoaderFaq;