import React, { useEffect, useState } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import LoaderHero from "./loader_Hero";
import LoaderBanner from "./loader_Banner";
import LoaderTestimonials from "./loader_Testimonials";
import LoaderLandings from "./loader_Landings";
import LoaderFeatures from "./loader_Features";
import LoaderFaq from "./loader_Faq";

const Loader = ({ component }) => {

    const initValues = { isXS: false, isSM: false, isMD: false, isLG: false, isXL: false, is2XL: false }
    const [breakPointControl, setBreakPointControl] = useState(initValues);

    const isXS = useMediaQuery("(max-width: 639px)");
    const isSM = useMediaQuery("(min-width: 640px) AND (max-width: 767px");
    const isMD = useMediaQuery("(min-width: 768px) AND (max-width: 1023px");
    const isLG = useMediaQuery("(min-width: 1024px) AND (max-width: 1280px");
    const isXL = useMediaQuery("(min-width: 1280px) AND (max-width: 1535px");
    const is2XL = useMediaQuery("(min-width: 1536px)");

    useEffect(() => {
        setBreakPointControl({ isXS: isXS, isSM: isSM, isMD: isMD, isLG: isLG, isXL: isXL, is2XL: is2XL });
    }, [isXS, isSM, isMD, isLG, isXL, is2XL]);

    switch (component) {
        case "Hero":
            return <LoaderHero breakPointControl={breakPointControl} />;
        case "Banner":
            return <LoaderBanner breakPointControl={breakPointControl} />;
        case "Testimonials":
            return <LoaderTestimonials />;
        case "Landings":
            return <LoaderLandings />;
        case "Features":
            return <LoaderFeatures breakPointControl={breakPointControl} />;
        case "Faq":
            return <LoaderFaq />;
    }
}

export default Loader;