export const quoter_config = {
  local_storage_data: false,
  redirection_url: "/products",
  url_global: "http://localhost:4200/ar/productos",
  redirection: true,
  geolocation: false,
  type: "default",
  language: "es",
  market: "global",
  min_days_quotation: 2,
  max_years_quotation: 3,
  max_days_quotation: 364,
  pax_limit: 9,
  age_groups: {
    age_1: 38,
    age_2: 75,
  },
  days_limit: 120,
  mode: "normal",
  dates: {
    weekdays: false,
  },
  view_mode: "default",
  seo: {
    btn_quote_id: "btn-cotizar",
  },
};

export const landings_config = {
  available_countries_ids: [52, 164, 155],
};
