import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translations: {
        index: {
          quote: "Cotar",
          quote2: "Cotar seguro",
          bannerlanding: "Seguro de viagem para Estados Unidos",
          headingPart1: "Siga viajando",
          headingPart2: " seguro",
          subHeading: "Assistência para você viajar pelo mundo",
          quoterHeading: "Cote agora o seu seguro viagem Assist 365",
          testimonials: {
            heading: "Sabemos como cuidar de você",
            subheading:
              "Ouvimos nossos clientes para oferecer a cobertura que atenda às suas necessidades.",
            content: {
              0: {
                name: "Juana",
                country_code: "ar",
                country_name: "Argentina",
                content:
                  "Eles foram recomendados por um influenciador que sigo e isso me deu confiança. Até agora, estou mais do...",
              },
              1: {
                name: "Jorge",
                country_code: "mx",
                country_name: "México",
                content:
                  "A contratação foi simples e a atenção da equipe foi muito boa. A companhia aérea perdeu minha bagagem e me dera...",
              },
              2: {
                name: "Marilusse",
                country_code: "cl",
                country_name: "Chile",
                content:
                  "Mês passado, em um voo interno na Europa entre Amsterdã e Paris, uma das minhas malas não chegou. Entrei em...",
              },
              3: {
                name: "Luciana",
                country_code: "ar",
                country_name: "Argentina",
                content:
                  "Gostei porque todos os planos cobrem covid, não é opcional. Por mais que esteja mais tranquilo, ter essa cobertur...",
              },
              4: {
                name: "Jorge",
                country_code: "mx",
                country_name: "México",
                content:
                  "A contratação foi simples e a atenção da equipe foi muito boa. A companhia aérea perdeu minha bagagem e me dera...",
              },
              5: {
                name: "Marilusse",
                country_code: "cl",
                country_name: "Chile",
                content:
                  "Mês passado, em um voo interno na Europa entre Amsterdã e Paris, uma das minhas malas não chegou. Entrei em...",
              },
            },
            contentpt: {
              0: {
                name: "Rafael",
                country_code: "br",
                country_name: "São Paulo",
                content:
                  "O seguro da Assist 365 foi essencial para lidar com um imprevisto. Muito satisfeito!",
              },
              1: {
                name: "Ana L.",
                country_code: "mx",
                country_name: "Mina Gerais",
                content:
                  "Viajei tranquila sabendo que estava protegida. Excelente atendimento da equipe!",
              },
              2: {
                name: "Gabriel S.",
                country_code: "cl",
                country_name: "Rio de Janeiro",
                content:
                  "Ótimo atendimento, rapidez e eficiência. O seguro viagem internacional foi um diferencial na minha viagem.",
              },
              3: {
                name: "Pedro S.",
                country_code: "ar",
                country_name: "Rio de Janeiro",
                content:
                  "O atendimento da Assist 365 foi fundamental em um pequeno acidente que tive em Bariloche.",
              },
              4: {
                name: "Jorge",
                country_code: "mx",
                country_name: "Mina Gerais",
                content:
                  "A contratação foi simples e a atenção da equipe foi muito boa. A companhia aérea perdeu minha bagagem e me dera...",
              },
              5: {
                name: "Marilusse",
                country_code: "cl",
                country_name: "São Paulo",
                content:
                  "Mês passado, em um voo interno na Europa entre Amsterdã e Paris, uma das minhas malas não chegou. Entrei em...",
              },
            },
          },
          landings: {
            heading: "Viajá de manera segura a los tops destinos",
          },
          features: {
            1: {
              title: "Viaje tranquilo",
              content:
                "Trabalhamos 24 horas por dia, 7 dias por semana, para lhe dar o melhor serviço.",
            },
            2: {
              title: "Encontre o melhor para você",
              content:
                "Não importa se você está em uma pequena pausa do trabalho ou prestes a embarcar em um aeroporto. Contrate rápido e fácil.",
            },
            3: {
              title: "Não se preocupe se você mudar o destino",
              content:
                "Nossas coberturas estão disponíveis em todo o mundo. Onde quer que você vá, se preocupe apenas em aproveitar.",
            },
          },
        },
        faqs: {
          0: {
            question: "O que é seguro viagem?",
            answer:
              "O seguro viagem é um serviço que oferece cobertura médica e apoio em caso de emergências durante sua viagem. Com a ASSIST 365, você terá proteção médica, repatriação médica, assistência jurídica, cobertura para perda de bagagem, entre outros serviços essenciais para garantir sua tranquilidade durante a viagem.",
          },
          1: {
            question:
              "Qual é a diferença entre seguro de viagem e seguro médico de viagem?",
            answer:
              "O seguro de viagem oferece uma cobertura abrangente que inclui benefícios médicos, assistência em caso de atraso de voos, extravio de bagagem e outros imprevistos relacionados à sua viagem. Já o seguro médico de viagem se concentra principalmente no atendimento médico durante sua estadia no exterior, cobrindo despesas médicas, hospitalização e repatriação.",
          },
          2: {
            question:
              "Quais são os benefícios de contratar um seguro de viagem internacional?",
            answer:
              "O seguro de viagem internacional oferece proteção mundial. Isso significa que você estará coberto em caso de doença ou lesão durante sua viagem ao exterior, além de receber assistência em caso de perda de bagagem, atraso de voos ou outras eventualidades que possam afetar sua experiência de viagem. Isso é muito importante, pois os custos desses imprevistos no exterior costumam ser muito caros. Com o seguro de viagem internacional, você pode ter certeza de que está coberto.",
          },
          3: {
            question: "Como posso obter seguro viagem com a ASSIST 365?",
            answer:
              "Obter seguro viagem com a ASSIST 365 é rápido e fácil. Você só precisa entrar em nosso site, selecionar seu destino e as datas de sua viagem, e você terá diferentes opções de cobertura. Você pode escolher o plano que melhor se adapta às suas necessidades e fazer a compra on-line de forma segura e confiável.",
          },
          4: {
            question:
              "É obrigatório contratar um seguro de viagem para viajar da Argentina?",
            answer:
              "Embora não seja obrigatório para todos os destinos, é altamente recomendável fazer um seguro de viagem quando estiver viajando da Argentina. Isso lhe dará proteção e assistência médica em caso de emergências durante sua viagem, evitando despesas imprevistas e garantindo sua tranquilidade.",
          },
        },
      },
    },
    es: {
      translations: {
        index: {
          quote: "Cotizar",
          quote2: "Cotizar seguro",
          bannerlanding: "Seguro de viaje para entrar a Estados Unidos",
          headingPart1: "Seguro de",
          headingPart2: " viaje",
          subHeading: "En todo el mundo al mejor precio",
          quoterHeading: "Cotiza ahora tu seguro de viaje Assist 365",
          testimonials: {
            heading: "Sabemos cómo cuidarte",
            subheading:
              "Escuchamos a nuestros clientes para acercarle la cobertura que se ajuste a sus necesidades.",
            content: {
              0: {
                name: "Eleonora",
                country_code: "ar",
                country_name: "Argentina",
                content:
                  "Agradezco su atención, el personal de servicio al cliente muy empático, la atención donde me derivaron muy buen...",
              },
              1: {
                name: "Jorge",
                country_code: "mx",
                country_name: "México",
                content:
                  "La contratación fue simple y la atención del equipo muy buena. La aerolínea extravió mi equipaje y me dieron soporte.",
              },
              2: {
                name: "Jonathan",
                country_code: "cl",
                country_name: "Chile",
                content:
                  "Empáticos y cordiales. Buena gestión. Volvería a contratar.",
              },
              3: {
                name: "Luisa",
                country_code: "ar",
                country_name: "Argentina",
                content:
                  "Gracias por estar pendiente de mi salud, les agradezco mucho. Muy agradecida, un servicio de calidad",
              },
              4: {
                name: "Jonathan",
                country_code: "cl",
                country_name: "Chile",
                content:
                  "Empáticos y cordiales. Buena gestión. Volvería a contratar.",
              },
              5: {
                name: "Jorge",
                country_code: "mx",
                country_name: "México",
                content:
                  "La contratación fue simple y la atención del equipo muy buena. La aerolínea extravió mi equipaje y me dieron soporte.",
              },
            },
          },
          landings: {
            heading: "Viaja de manera segura a los tops destinos",
          },
          landingsTop: {
            heading: "Requisitos para viajar a los principales destinos",
            btnReadMore: "Seguir leyendo",
          },
          features: {
            1: {
              title: "Viaja con tranquilidad",
              content:
                "No te preocupes si te duele una muela en Sudáfrica a la madrugada. Trabajamos 24/7 para darte el mejor servicio.",
            },
            2: {
              title: "Encuentra lo mejor para ti",
              content:
                "No importa si estás en un pequeño break de tu trabajo o si estás por embarcar en algún aeropuerto. Contrata rápido y fácil.",
            },
            3: {
              title: "No te preocupes si cambias de destino",
              content:
                "Nuestras coberturas sirven en todo el mundo. Vayas a donde vayas, solo preocúpate por pasarla bien.",
            },
          },
        },
        faqs: {
          0: {
            question: "¿Qué es un seguro de viaje y para qué sirve?",
            answer:
              "Un seguro de viaje es un servicio que brinda asistencia general y soporte médico en caso de emergencias durante tu viaje. Con ASSIST 365 obtendrás protección médica, repatriación sanitaria, asistencia legal,  cobertura por pérdida de equipaje, entre varios otros servicios esenciales para garantizar tu tranquilidad mientras viajas.",
          },
          1: {
            question:
              "¿Cuál es la diferencia entre un seguro de viaje y un seguro médico para viajar?",
            answer:
              "Un seguro de viaje proporciona una amplia cobertura que incluye prestaciones médicas, asistencia por vuelo demorado, pérdida de equipaje y otros imprevistos relacionados con tu viaje. Mientras que un seguro médico para viajar se enfoca principalmente en la atención médica durante tu estadía en el extranjero, cubriendo gastos médicos, hospitalización y repatriación sanitaria.",
          },
          2: {
            question:
              "¿Cuáles son los beneficios de contratar un seguro de viaje internacional?",
            answer:
              "Un seguro de viaje internacional te brinda protección en todo el mundo. Esto significa que estarás cubierto en caso de enfermedad o lesión durante tu viaje al extranjero, además de recibir asistencia en caso de pérdida de equipaje, retraso de vuelos u otras eventualidades que puedan afectar tu experiencia de viaje. Esto es muy importante, ya que los costos por estos imprevistos en el exterior, suelen ser muy costos. Con un seguro de viaje internacional, te aseguras la tranquilidad de estar cubierto.",
          },
          3: {
            question:
              "¿Cómo puedo obtener asistencia al viajero con ASSIST 365?",
            answer:
              "Obtener asistencia al viajero con ASSIST 365 es rápido y sencillo. Solo necesitas ingresar a nuestro sitio web, seleccionar tu destino y las fechas de tu viaje, y obtendrás diferentes opciones de cobertura. Puedes elegir el plan que mejor se adapte a tus necesidades y realizar la compra online de forma segura y confiable.",
          },
          4: {
            question:
              "¿Es obligatorio contratar un seguro de viaje para viajar al exterior?",
            answer:
              "Aunque no es obligatorio para todos los destinos, se recomienda encarecidamente contratar un seguro de viaje al viajar desde tu país de residencia hacia otro. Esto te brindará protección y asistencia médica en caso de emergencias durante tu viaje, evitando gastos imprevistos y garantizando tu tranquilidad.",
          },
        },
      },
    },
    "es-AR": {
      translations: {
        index: {
          quote: "Cotizar",
          quote2: "Cotizar seguro",
          bannerlanding: "Seguro de viaje para entrar a Estados Unidos",
          headingPart1: "Asistencia",
          headingPart2: "al viajero",
          subHeading: "Tu seguro de viaje para todo el mundo",
          quoterHeading: "Cotizá ahora tu asistencia al viajero Assist 365",
          testimonials: {
            heading: "Sabemos cómo cuidarte",
            subheading:
              "Escuchamos a nuestros clientes para acercarles la cobertura que se ajuste a sus necesidades.",
            content: {
              0: {
                name: "Eleonora",
                country_code: "ar",
                country_name: "Argentina",
                content:
                  "Agradezco su atención, el personal de servicio al cliente muy empático, la atención donde me derivaron muy buen...",
              },
              1: {
                name: "Jorge",
                country_code: "mx",
                country_name: "México",
                content:
                  "La contratación fue simple y la atención del equipo muy buena. La aerolínea extravió mi equipaje y me dieron soporte.",
              },
              2: {
                name: "Jonathan",
                country_code: "cl",
                country_name: "Chile",
                content:
                  "Empáticos y cordiales. Buena gestión. Volvería a contratar.",
              },
              3: {
                name: "Luisa",
                country_code: "ar",
                country_name: "Argentina",
                content:
                  "Gracias por estar pendiente de mi salud, les agradezco mucho. Muy agradecida, un servicio de calidad",
              },
              5: {
                name: "Jonathan",
                country_code: "cl",
                country_name: "Chile",
                content:
                  "Empáticos y cordiales. Buena gestión. Volvería a contratar.",
              },
              5: {
                name: "Jorge",
                country_code: "mx",
                country_name: "México",
                content:
                  "La contratación fue simple y la atención del equipo muy buena. La aerolínea extravió mi equipaje y me dieron soporte.",
              },
            },
          },
          features: {
            1: {
              title: "Viajá con tranquilidad",
              content:
                "No te preocupes si te duele una muela en Sudáfrica a la madrugada. Trabajamos 24/7 para darte el mejor servicio.",
            },
            2: {
              title: "Encontrá lo mejor para vos",
              content:
                "No importa si estás en un pequeño break de tu trabajo o si estás por embarcar en algún aeropuerto. Contratá rápido y fácil.",
            },
            3: {
              title: "No te preocupes si cambiás de destino",
              content:
                "Nuestras coberturas sirven en todo el mundo. Vayas a donde vayas, sólo preocupate por pasarla bien.",
            },
          },
          landings: {
            heading: "Viajá de manera segura a los destinos más visitados",
          },
          landingsTop: {
            heading: "Requisitos para viajar a los principales destinos",
            btnReadMore: "Seguir leyendo",
          },
        },
        faqs: {
          0: {
            question: "¿Qué es la asistencia al viajero?",
            answer:
              "La asistencia al viajero es un servicio que brinda cobertura y soporte médico en caso de emergencias durante tu viaje. Con ASSIST 365 obtendrás protección médica, repatriación sanitaria, asistencia legal,  cobertura por pérdida de equipaje, entre varios otros servicios esenciales para garantizar tu tranquilidad mientras viajas.",
          },
          1: {
            question:
              "¿Cuál es la diferencia entre un seguro de viaje y un seguro médico para viajar?",
            answer:
              "Un seguro de viaje proporciona una amplia cobertura que incluye prestaciones médicas, asistencia por vuelo demorado, pérdida de equipaje y otros imprevistos relacionados con tu viaje. Mientras que un seguro médico para viajar se enfoca principalmente en la atención médica durante tu estadía en el extranjero, cubriendo gastos médicos, hospitalización y repatriación sanitaria.",
          },
          2: {
            question:
              "¿Cuáles son los beneficios de contratar un seguro de viaje internacional?",
            answer:
              "Un seguro de viaje internacional te brinda protección en todo el mundo. Esto significa que estarás cubierto en caso de enfermedad o lesión durante tu viaje al extranjero, además de recibir asistencia en caso de pérdida de equipaje, retraso de vuelos u otras eventualidades que puedan afectar tu experiencia de viaje. Esto es muy importante, ya que los costos por estos imprevistos en el exterior, suelen ser muy costos. Con un seguro de viaje internacional, te asegurás la tranquilidad de estar cubierto.",
          },
          3: {
            question:
              "¿Cómo puedo obtener asistencia al viajero con ASSIST 365?",
            answer:
              "Obtener asistencia al viajero con ASSIST 365 es rápido y sencillo. Sólo necesitás ingresar a nuestro sitio web, seleccionar tu destino y las fechas de tu viaje, y obtendrás diferentes opciones de cobertura. Podés elegir el plan que mejor se adapte a tus necesidades y realizar la compra online de forma segura y confiable.",
          },
          4: {
            question:
              "¿Es obligatorio contratar un seguro de viaje para viajar desde Argentina?",
            answer:
              "Aunque no es obligatorio para todos los destinos, se recomienda encarecidamente contratar un seguro de viaje al viajar desde Argentina. Esto te brindará protección y asistencia médica en caso de emergencias durante tu viaje, evitando gastos imprevistos y garantizando tu tranquilidad.",
          },
        },
      },
    },
  },
  lng: "es-AR",
  fallbackLng: "pt-BR",
  ns: ["translations"],
  defaultNS: "translations",
});
export default i18n;
