import React from "react";
import { useTranslation } from "react-i18next";

const LoaderLandings = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="animate-pulse flex flex-col">
                <div className="flex">
                    <div className="rounded-md bg-slate-700 opacity-15 max-w-[700px] mt-[106px] xl:mt-32 xl:mb-[70px] mx-auto mb-6">
                        <h2 className='invisible font-semibold font-titillium text-3xl sm:text-4xl xl:text-5xl text-center'>
                            {t("index.landings.heading")}
                        </h2>
                    </div>
                </div>
                <div className="flex space-x-5 xl:space-x-10">
                    <div className='rounded-[20px] bg-slate-700 opacity-15 flex flex-col pt-3 pb-[14px] px-5 w-full h-[428px] sm:w-[300px] xl:w-[360px] min-h-[465px]'> </div>
                    <div className='rounded-[20px] bg-slate-700 opacity-15 hidden sm:block pt-3 pb-[14px] px-5 w-full h-[428px] sm:w-[300px] xl:w-[360px] min-h-[465px]'> </div>
                    <div className='rounded-[20px] bg-slate-700 opacity-15 hidden xl:block pt-3 pb-[14px] px-5 w-full h-[428px] sm:w-[300px] xl:w-[360px] min-h-[465px]'> </div>
                </div>
            </div>
        </>
    )
}

export default LoaderLandings;