import React from "react";
import { useTranslation } from "react-i18next";
import Separator from "../separator/Separator";

const LoaderHero = ({ breakPointControl }) => {

    const { t } = useTranslation();

    return (
        <div className="hero-wrapper w-full min-h-[640px] xl:min-h-[680px]">
            <div className="hero-background hidden sm:block"></div>
            <div className="hero-content flex flex-col items-center justify-center mx-auto">
                <div className="animate-pulse w-full max-w-[358px] sm:max-w-[633px] xl:max-w-[1210px] px-4">
                    <div class="flex justify-center md:justify-start">
                        <div class="rounded-md bg-slate-700 opacity-15 mt-9 md:mt-[103px]">
                            <h1 className='invisible font-normal xleading-[52px] font-titillium text-[30px] md:text-5xl xl:text-[64px]'>
                                {t("index.headingPart1")}{" "}
                                <span className='font-bold'>{t("index.headingPart2")}</span>
                            </h1>
                        </div>
                    </div>

                    <div class="flex justify-center md:justify-start">
                        <div class="rounded-md bg-slate-700 opacity-15 mt-1 mb-4 md:my-6 xl:my-9">
                            <h3 className="invisible text-xl md:text-2xl xl:text-[30px] font-titillium">
                                {t("index.subHeading")}
                            </h3>
                        </div>
                    </div>

                    {breakPointControl?.isXS && (
                        <div class="flex justify-center md:justify-start">
                            <div className='rounded-md bg-slate-700 opacity-15 text-base md:text-xl font-semibold font-titillium mb-[10px] md:mb-5 pl-1'>
                                <h2 className="invisible">{t("index.quoterHeading")}</h2>
                            </div>
                        </div>
                    )}

                    {breakPointControl?.isXS ? (
                        <div className="flex flex-col space-y-[10px]">
                            <div class="bg-slate-700 opacity-15 h-[70px] w-[328px] rounded-[8px]"></div>
                            <div class="bg-slate-700 opacity-15 h-[70px] w-[328px] rounded-[8px]"></div>
                            <div class="bg-slate-700 opacity-15 h-[70px] w-[328px] rounded-[8px]"></div>
                            <div class="bg-slate-700 opacity-15 h-[70px] w-[328px] rounded-[8px]"></div>
                            <div class="bg-slate-700 opacity-15 h-[60px] w-[328px] rounded-[8px]"></div>
                        </div>
                    ) :
                        (<div class="bg-slate-700 opacity-15 h-[310px] w-[592px] rounded-[20px]"></div>)}

                </div>
            </div>
            <Separator />
        </div>
    )
}

export default LoaderHero;