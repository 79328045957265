import React from "react";

const LoaderBanner = ({ breakPointControl }) => {
    return (
        <div className="animate-pulse lg:flex mt-20 overflow-hidden flex items-center justify-between">
            <div className='relative overflow-hidden bg-slate-700 opacity-15 h-[238px] flex items-center justify-start rounded-lg w-full xl:w-[575px]'></div>
            <div className='relative overflow-hidden bg-slate-700 opacity-15 h-[238px] flex items-center justify-start rounded-lg w-full xl:w-[575px]'></div>
        </div>
    )
}

export default LoaderBanner;